.notFoundHeading {
  font-size: 45px;
  line-height: 1.08365;
  font-weight: 300;
  letter-spacing: -0.03em;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  text-wrap: balance;
  margin: 20px 185px;
}

.notfoundButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(180deg, #4b91f7 0%, #367af6 100%);
  background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.notfoundButton:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

/* Mobile */
@media (max-width: 480px) {
  .notFoundHeading {
    font-weight: 300;
    font-size: 16px;
    margin: 10px 135px 10px 20px;
    text-wrap: wrap;
  }
}

/* Tablets */
@media (max-width: 768px) {
  .notFoundHeading {
    font-size: 20px;
  }
}

/* Small Screens */
@media (max-width: 1024px) {
  .notfoundHeading {
    font-size: 40px;
  }
}

/* Desktop */
@media (max-width: 1200px) {
  .notFoundHeading {
    font-size: 50px;
  }
}
