@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.suggestedText {
  font-size: 8px;
}

.postDescriptionText {
  font-size: 12px;
  line-height: 25px;
}

.skuMorphic {
  --text-opacity: 1;
  background-image: linear-gradient(180deg, #fff, #f5f5fa);
  border-radius: 9999px;
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
    0 1px 3px 0 rgba(93, 100, 148, 0.2);
  color: #484c7a;
  color: rgba(72, 76, 122, var(--text-opacity));
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease-out;
}
